import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout'
import BrandCTA from '../../../components/brand-cta'
import polaroid from '../../../images/What_SLA_polaroid.png'

const metaTags = {
  description: 'Learn why it\'s important to treat central precocious puberty (CPP).',
  keywords: 'Homepage',
  title: 'Why Treat CPP'
}

const IndexPage = () => (
  <Layout meta={metaTags}>
    <Row>
      <Col xs={12}>
        <h1>Why treat CPP?</h1>
      </Col>
    </Row>
    <Row>
      <Col xs={12} md={6}>
        <p>By starting and completing puberty too early, central precocious puberty (CPP) may prevent your child from reaching his or her full adult height.</p>
        <h2>Full growth potential </h2>
        <p>Puberty usually causes a growth spurt. So, right now, your child might be taller than his or her peers, but a growth spurt that happens too early may mean your child could end up shorter as an adult than he or she might otherwise have been.</p>
        <h2>CPP treatments stop the puberty signal </h2>
        <p>Your child’s healthcare professional may recommend a medicine called a GnRH agonist. This is a medicine that can help stop puberty by blocking the release of sex hormones in the body.</p>
        <p>Just talking to your child about the condition can make a big difference. Let him or her know that puberty is a normal process that everyone goes through, but for some children, puberty happens earlier than it should.</p>

      </Col>
      <Col xs={12} md={5}>
        <img
          src={polaroid}
          alt="Polaroid 3"
          title="Polaroid 3"
        />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
      </Col>
    </Row>
    <Row between="xs">
      <Col xs={12} md={6}>
        <BrandCTA
          href={'/patients/caregiver-signup'}
          LinkCaption="Order an all-in-one Caregiver Kit for information about SUPPRELIN<sup>&reg;</sup> LA and CPP"
        >
          Order an all-in-one Caregiver Kit for information about SUPPRELIN<sup>&reg;</sup> LA and CPP
        </BrandCTA>
      </Col>
      <Col xs={12} md={6}>
        <BrandCTA
          href="/patients/caregiver-resources/#video=3"
          LinkCaption="Watch a mother and daughter share their experience with SUPPRELIN<sup>&reg;</sup> LA"
        >
          Watch a mother and daughter share their experience with SUPPRELIN<sup>&reg;</sup> LA
        </BrandCTA>
      </Col>
    </Row>
  </Layout>
)

export default IndexPage
