import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import ActiveLink from '../active-link'
import './brand-cta.scss'

const BrandCTA = props => {

   return (
    <div className="brand-call-out-container">
      <Row center="xs">
        <Col xs={12}>
          <ActiveLink
            EnableActiveClass={false}
            to={props.href}
            EventObject={{
              category: 'Brand Call to Action',
              action: 'Click',
              label: props.LinkCaption,
            }}
            className="call-out-copy"
            {...props}
          >
            {props.children}
          </ActiveLink>
        </Col>
      </Row>
    </div>
  )
}

export default BrandCTA
